import React, { useState, useContext } from "react";
import UserContext from "../../../context/user/UserContext";

import PropTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";

import BeatLoader from "react-spinners/BeatLoader";

import { FaEdit, FaTrash, FaCheck, FaTimes } from "react-icons/fa";

import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";

import { toast } from "react-toastify";

import { saveAs } from "file-saver";

import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MenuItem from "@mui/material/MenuItem";

import "./User.css";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const AllUsers = () => {
  const theme = useTheme();
  const { usersData, load, setLoading, fetchUsers, disableLoading } =
    useContext(UserContext);
  const [pageSize, setPageSize] = React.useState(5);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchUsers();
    // disableLoading();
  }, []);

  const notify = () =>
    toast.success("User Status Changed Successfully", {
      theme: "colored",
      toastId: "deleteUsersSuccessToast",
    });

  const rows = usersData.map((row) => ({
    id: row.id,
    email: row.email,
    firstName: row.firstName,
    lastName: row.lastName,
    phoneNumber: row.phoneNumber,
    role: row.role === "" ? "Non Admin" : "Admin",
    lockoutEnd: row.lockoutEnd === null ? "Active" : "Inactive",
    studies: row.studies,
  }));

  const exportData = usersData.map((row) => ({
    Email: row.email,
    "First Name": row.firstName,
    "Last Name": row.lastName,
    "Phone Number": row.phoneNumber,
    Role: row.role === "" ? "Non Admin" : "Admin",
    "Is Active": row.lockoutEnd === null ? "Active" : "Inactive",
    Studies: row.studies.length === 0 ? "No Studies" : row.studies,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 250,
      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      renderHeader: () => <strong>{"First Name"}</strong>,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      renderHeader: () => <strong>{"Last Name"}</strong>,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 250,
      renderHeader: () => <strong>{"Phone"}</strong>,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      renderHeader: () => <strong>{"Role"}</strong>,
    },
    {
      field: "studies",
      headerName: "Edit",
      width: 250,
      renderHeader: () => <strong>{"Edit"}</strong>,
      renderCell: (params) => {
        const id = params.row.id;
        const email = params.row.email;
        const firstName = params.row.firstName;
        const lastName = params.row.lastName;
        const phone = params.row.phoneNumber;
        const role = params.row.role === "" ? "Non Admin" : "Admin";
        const status = params.row.lockoutEnd === null ? "Active" : "Inactive";

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              // color: "white",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              try {
                navigate(`/edit-user/`, {
                  state: {
                    id: id,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    role: role,
                    status: status
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaEdit
              style={{
                fontSize: "20px",
                color: "red !important",
                // marginLeft: "60px",
              }}
            />
          </button>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderHeader: () => <strong>{"Status"}</strong>,
      renderCell: (params) => {
        const status = params.row.lockoutEnd === "Active" ? true : false;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {status ? (
              <FaCheck
                color="#3661eb"
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            ) : (
              <FaTimes
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            )}
          </button>
        );
      },
    },
  ];

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Users.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Users.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={exportData} />
      <GridExportToPDF rows={exportData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  GridExportToExcel.propTypes = {
    hideMenu: PropTypes.func,
  };

  GridExportToPDF.propTypes = {
    hideMenu: PropTypes.func,
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link> |{" "}
            <Link to="/all-users">All Users</Link>
          </p>

          <Row>
            <Col md={6}>
              <p className="sponsor-heading">All Users</p>
            </Col>
            <Col md={6}>
              <div className="userButtonContainer">
                <Link to="/add-user">
                  <button className="addUserButton">+ Add New User</button>
                </Link>
              </div>
            </Col>
          </Row>

          <Box sx={{ height: 400, width: "100%" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allUsers-grid"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: CustomToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>
        </div>
      )}
    </>
  );
};

export default AllUsers;
