import React, { useContext } from "react";
import StudyContext from "../../../context/study/StudyContext";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import "./Study.css";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const TestSponsor = () => {
  const theme = useTheme();

  const { studyData, load, setLoading, disableLoading, fetchStudies } =
    useContext(StudyContext);

  const [pageSize, setPageSize] = React.useState(5);

  const notify = () =>
    toast.success("Active Status Changed Successfully", {
      theme: "colored",
      toastId: "deleteSponsorSuccessToast",
    });

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchStudies();
    // disableLoading();
  }, []);

  const rows = studyData.map((row) => ({
    id: row.id,
    name: row.name,
    portalUrl: row.portalUrl,
    apiUrl: row.apiUrl,
    questionnaireBuilderUrl: row.questionnaireBuilderUrl,
    sponsorId: row.sponsorId,
    sponsorName: row.sponsor.name,
    studyType: row.studyType.name,
    isActive: row.isActive === true ? "Active" : "Inactive",
    projectManagers: row.projectManagers,
  }));

  const exportData = studyData.map((row) => ({
    Name: row.name,
    "Portal URL": row.portalUrl,
    "API URL": row.apiUrl,
    "Questionnaire URL": row.questionnaireBuilderUrl,
    "Sponsor Name": row.sponsor.name,
    "Study Type": row.studyType.name,
    "Is Active": row.isActive === true ? "Active" : "Inactive",
    "Project Managers": row.projectManagers,
  }));

  // React.useEffect(() => {
  //   console.log("All Studies Rows ===> ", rows);
  // }, []);

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Studies.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Studies.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  GridExportToExcel.propTypes = {
    hideMenu: PropTypes.func,
  };

  GridExportToPDF.propTypes = {
    hideMenu: PropTypes.func,
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={exportData} />
      <GridExportToPDF rows={exportData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 100,
      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "sponsorName",
      headerName: "Sponsor Name",
      width: 220,
      renderHeader: () => <strong>{"Sponsor Name"}</strong>,
    },
    {
      field: "portalUrl",
      headerName: "Portal URL",
      width: 220,
      renderHeader: () => <strong>{"Portal URL"}</strong>,
    },
    {
      field: "apiUrl",
      headerName: "API URL",
      width: 220,
      renderHeader: () => <strong>{"API URL"}</strong>,
    },
    {
      field: "questionnaireBuilderUrl",
      headerName: "Questionnaire URL",
      width: 220,
      renderHeader: () => <strong>{"Questionnaire URL"}</strong>,
    },
    {
      field: "sponsorId",
      headerName: "Sponsor ID",
      hide: true,
      width: 220,
      renderHeader: () => <strong>{"Sponsor ID"}</strong>,
    },
    {
      field: "studyType",
      headerName: "Study Type",
      hide: true,
      width: 220,
      renderHeader: () => <strong>{"Study Type"}</strong>,
    },
    {
      field: "users",
      headerName: "Edit",
      width: 220,
      renderHeader: () => <strong>{"Edit"}</strong>,
      renderCell: (params) => {
        const id = params.row.id;
        const sponsorName = params.row.sponsorName;
        const studyname = params.row.name;
        const studyType = params.row.studyType;
        const sponsorId = params.row.sponsorId;
        const portalUrl = params.row.portalUrl;
        const apiUrl = params.row.apiUrl;
        const questionnaireBuilderUrl = params.row.questionnaireBuilderUrl;
        const isActive = params.row.isActive === "Active" ? true : false;
        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              try {
                navigate(`/edit-study/`, {
                  state: {
                    id: id,
                    sponsorName: sponsorName,
                    studyname: studyname,
                    sponsorId: sponsorId,
                    studyType: studyType,
                    portalUrl: portalUrl,
                    apiUrl: apiUrl,
                    questionnaireBuilderUrl: questionnaireBuilderUrl,
                    isactive: isActive,
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaEdit
              style={{
                fontSize: "20px",
                color: "red !important",
              }}
            />
          </button>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 220,
      renderHeader: () => <strong>{"Active"}</strong>,
      renderCell: (params) => {
        const id = params.row.id;
        const status = params.row.isActive === "Active" ? true : false;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {status ? (
              <FaCheck
                color="#3661eb"
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            ) : (
              <FaTimes
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            )}
          </button>
        );
      },
    },
  ];

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link> |{" "}
            <Link to="/all-studies">All Studies</Link>
          </p>
          <Row>
            <Col md={6}>
              <p className="sponsor-heading">All Studies</p>
            </Col>
            <Col md={6}>
              <div className="userButtonContainer">
                <Link to="/create-study">
                  <button className="addUserButton">+ Add New Study</button>
                </Link>
              </div>
            </Col>
          </Row>
          {/* getMuiTheme */}
          <Box sx={{ height: 400, width: "100%" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allStudies-grid"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: CustomToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>
        </div>
      )}
    </>
  );
};

export default TestSponsor;
