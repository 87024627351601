import React, { useContext } from "react";
import SponsorContext from "../../../context/sponsor/SponsorContext";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";
import { saveAs } from "file-saver";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  gridFilteredSortedRowIdsSelector,
  // useGridApiContext
} from "@mui/x-data-grid-pro";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import "./Sponsors.css";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".MuiDataGrid-toolbarContainer": {
    float: "right !important",
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const AllSponsors = () => {
  const theme = useTheme();

  // const apiRef = useGridApiContext();

  const { sponsorsData, load, setLoading, fetchSponsors, disableLoading } =
    useContext(SponsorContext);
  const [pageSize, setPageSize] = React.useState(5);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchSponsors();
    // disableLoading();
  }, []);

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 320,
      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 320,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "dateCreatedAt",
      headerName: "Date Created",
      width: 320,
      renderHeader: () => <strong>{"Date Created"}</strong>,
      renderCell: (params) => {
        const date = params.row.dateCreatedAt;
        return (
          <>
            {/* <div>{moment(date).format("YYYY-MM-DD")}</div> */}
            <div>{date}</div>
          </>
        );
      },
    },
    {
      field: "timeCreatedAt",
      headerName: "Time Created",
      width: 320,
      renderHeader: () => <strong>{"Time Created"}</strong>,
      renderCell: (params) => {
        const time = params.row.timeCreatedAt;
        return (
          <>
            <div>{time}</div>
          </>
        );
      },
    },
    {
      field: "timezoneCreatedAt",
      headerName: "Time Zone",
      width: 320,
      renderHeader: () => <strong>{"Time Zone"}</strong>,
      renderCell: (params) => {
        const timeZone = params.row.timezoneCreatedAt;
        return (
          <>
            <div>{timeZone}</div>
          </>
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 320,
      renderHeader: () => <strong>{"Created By"}</strong>,
    },
    {
      field: "imageUrl",
      headerName: "Image URL",
      width: 320,
      hide: true,
      renderHeader: () => <strong>{"Image URL"}</strong>,
    },
    {
      field: "secret",
      headerName: "Edit",
      width: 320,
      renderHeader: () => <strong>{"Edit"}</strong>,
      renderCell: (params) => {
        const id = params.row.id;
        const name = params.row.name;
        const fileUrl = params.row.imageUrl;
        const isActive = params.row.isActive === "Active" ? true : false;
        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              try {
                navigate(`/edit-sponsor/`, {
                  state: {
                    id: id,
                    name: name,
                    fileUrl: fileUrl,
                    isactive: isActive,
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaEdit
              style={{
                fontSize: "20px",
                color: "red !important",
              }}
            />
          </button>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 320,
      renderHeader: () => <strong>{"Active"}</strong>,
      renderCell: (params) => {
        const id = params.row.id;
        const status = params.row.isActive === "Active" ? true : false;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {status ? (
              <FaCheck
                color="#3661eb"
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            ) : (
              <FaTimes
                style={{
                  fontSize: "20px",
                  color: "red !important",
                }}
              />
            )}
          </button>
        );
      },
    },
  ];

  const rows = sponsorsData.map((row) => ({
    id: row.id,
    name: row.name,
    dateCreatedAt: row.dateCreatedAt,
    timeCreatedAt: row.timeCreatedAt,
    timezoneCreatedAt: row.timezoneCreatedAt,
    createdBy: row.createdBy,
    imageUrl: row.imageUrl,
    isActive: row.isActive === true ? "Active" : "Inactive",
  }));

  const exportData = sponsorsData.map((row) => ({
    Name: row.name,
    "Date Created": row.dateCreatedAt,
    "Time Created": row.timeCreatedAt,
    "Time Zone Created At": row.timezoneCreatedAt,
    "Created By": row.createdBy,
    "Is Active": row.isActive === true ? "Active" : "Inactive",
  }));

  // const getFilteredRows = (rows) => {
  //   return gridFilteredSortedRowIdsSelector(rows);
  // };

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Sponsors.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Sponsors.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const GridExportFilteredRows = () => {
    return (
      <MenuItem
        onClick={() => {
          // const filteredRows = gridFilteredSortedRowIdsSelector(apiRef);
          // console.log("Filtered Rows ===> ", filteredRows);
        }}
        // onClick={async () => {
        //   try {
        //     const res = await postExportExcel(props.rows);
        //     if (res.status === 200) {
        //       const res2 = await getDownloadReport(res.data);
        //       if (res2.status === 200) {
        //         const blob = new Blob([res2.data], {
        //           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //         });
        //         saveAs(blob, "Sponsors.xlsx");
        //       }
        //     }
        //     props.hideMenu?.();
        //   } catch (err) {
        //     console.log("ERROR: ", err);
        //   }
        // }}
      >
        Filtered rows
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={exportData} />
      <GridExportToPDF rows={exportData} />
      <GridExportFilteredRows />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  GridExportToExcel.propTypes = {
    hideMenu: PropTypes.func,
  };

  GridExportToPDF.propTypes = {
    hideMenu: PropTypes.func,
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link> |{" "}
            <Link to="/all-sponsors">All Sponsors</Link>
          </p>
          <Row>
            <Col md={6}>
              <p className="sponsor-heading">All Sponsors</p>
            </Col>
            <Col md={6}>
              <div className="userButtonContainer">
                <Link to="/create-sponsor">
                  <button className="addUserButton">+ Add New Sponsor</button>
                </Link>
              </div>
            </Col>
          </Row>
          {/* getMuiTheme */}
          <Box sx={{ height: 400, width: "100%" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allSponsors-grid"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: CustomToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>
        </div>
      )}
    </>
  );
};

export default AllSponsors;
